import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from "./pages/Home";
import DJ from "./pages/DJ";
import Blog from "./pages/Blog";
import BlogPost from './pages/BlogPost';
import ReactGA from 'react-ga4';
import Bama from './pages/Bama';
import { HelmetProvider } from 'react-helmet-async';

const useTrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
      const pagePath = location.pathname + location.search;
      ReactGA.send({ hitType: "pageview", page: pagePath });
  }, [location]);
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <PageViewTracker />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/words/:title" element={<BlogPost />} />
          <Route path="/munch" element={<DJ />} />
          <Route path="/words" element={<Blog />} />
          <Route path="/bama" element={<Bama />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

const PageViewTracker = () => {
  useTrackPageView();
  return null;
};

export default App;
