import React from 'react';
import "../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
const Solo = styled.div`
    // gap: 5%;
    margin-top: 7%;
    text-align: start;

    @media only screen and (max-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 10%;
        margin-right: 10%;
        max-width: 80%;
    }
    @media only screen and (min-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;

        .nowrap {
            white-space: nowrap;
        }
    }
`;


const SpaceGrotesk = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 5px;
  /* font-weight: 200; */
  line-height: 18px;

`

const Orbitron = styled.div`
  font-family: Orbitron;
  font-size: 24px;
  font-weight: 700;
`

const Hyperlink = styled.a`
  font-family: Open Sans;
  /* color: ${({secondary}) => secondary ? 'black' : 'blue'}; */
  color: blue;
  
  :hover {
    cursor: help;
  }
`

const Nunito = styled(Link)`
  font-family: Open Sans;
  font-size: 1em;
  padding-top: 1px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &::after {
    content: '↗';
    font-size: 8px;
    font-weight: 900;
    position: absolute;
    top: 5px;
    right: -10px;
    @media only screen and (max-width: 768px) {
      right: -16px;
    }
    color: black;
    opacity: 1;
  }
`;

function Blog() {
  return (
    <>
    <Helmet>
        <title>Ashank's Blog</title>
        <meta name="description" content="My thoughts on anything" />
        <meta property="og:title" content="Ashank's Blog" />
        <meta property="og:description" content="My thoughts on anything" />
        <meta property="og:url" content="https://ashank.xyz/blog" />
        <meta property="og:type" content="website" />
      </Helmet>
    <div className="App">
      <Solo>
        <Orbitron>My thoughts</Orbitron> <br/>
        <i style={{ fontSize: '0.8em' }}>January 2025</i><br/>
        <Nunito to="/words/geccs-convergence">geccs convergence</Nunito><br/><br/>

        <i style={{ fontSize: '0.8em' }}>December 2024</i><br/>
        <Nunito to="/words/destiny">destiny</Nunito><br/><br/>

        <i style={{ fontSize: '0.8em' }}>September 2024</i><br/>
        <Nunito to="/words/american-manufacturing-funding">american manufacturing funding</Nunito><br/>
        <Nunito to="/words/oxford-study">oxford study</Nunito><br/><br/>

        <i style={{ fontSize: '0.8em' }}>August 2024</i><br/>
        <Nunito to="/words/string-theory">string theory is a psyop</Nunito><br/><br/>

        <i style={{ fontSize: '0.8em' }}>June 2024</i><br/>
        <Nunito to="/words/open-source-cad">open source cad</Nunito><br/><br/>
        

        <hr/>
<SpaceGrotesk>These thoughts are mine and subject to change. Not financial advice. Not any kind of advice. <Hyperlink style={{fontSize: '11px'}}href="https://www.ashank.xyz">Go home</Hyperlink>.</SpaceGrotesk><br/>
      </Solo>
    </div>
    </>
  );
}

export default Blog;
