import "../App.css";
import styled from "styled-components";
import { FaLinkedin, FaTwitter, FaInstagram, FaSpotify } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';

import React, { useEffect, useState } from 'react';
import getNowPlayingItem from "../components/Spotify/api";

const TWITTER = 'https://twitter.com/elebenteen'
const INSTAGRAM = 'https://www.instagram.com/1ashank/'
const LINKEDIN = 'https://www.linkedin.com/in/ashankbehara/'
const SPOTIFY = 'https://open.spotify.com/user/elebenteen'

const Solo = styled.div`
    @media only screen and (max-width: 768px) {  
    /* Your CSS Code for this device size */    
      margin-left: 10%;
      margin-right: 10%;
      width: 80%;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 768px) {  
    /* Your CSS Code for this device size */    
      margin-left: 25%;
      margin-right: 25%;
      width: 50%;
    }
  // gap: 5%;
  margin-top: 15%;
  text-align: start;
`;

const SpaceGrotesk = styled.div`
  font-family: Open Sans;
  font-size: 13px;
  margin-bottom: 5px;
  /* font-weight: 200; */
  line-height: 18px;

`

const Merriweather = styled.div`
  font-family: Open Sans;
  font-size: 12px;
`

const Rokkitt = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  display: flex;
  position: relative;
  align-items: center;

  div {
    display: inline-block;
  }

  hr {
    width: 100%;
    border-top: 1px solid black;
    margin: 3px 10px;
}  
`

const Orbitron = styled.div`
  font-family: Orbitron;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  cursor: help;
`;

const Kharla = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 7px;
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 6fr;
  grid-gap: 5px;
`

const Spectral = styled.div`
  font-family: Open Sans;
  font-size: 13px;
  align-items: center;
`

const Nunito = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  display: flex; /* Add this line */
  align-items: center; /* Ensures vertical alignment */
  flex-wrap: wrap; /* Allows items to wrap if needed */
  padding-top: 1px;
  display: inline-block;
`

const Hyperlink = styled.a`
  font-family: Open Sans;
  overflow-wrap: break-word;
  word-break: break-all; 
  /* color: ${({secondary}) => secondary ? 'black' : 'blue'}; */
  color: blue;
  
  :hover {
    cursor: help;
  }
`

const Drawer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 100%;
  padding: 10px;
  z-index: 1;
  flex-direction: row;
  animation: ${({ visible }) => (visible ? 'bounceRight 0.3s ease-out' : 'none')};

  ${Orbitron}:hover & {
    display: flex;
    animation: bounceRight 0.3s ease-out;
  }

  a {
    color: black;
    font-size: 12px;
    margin: 0 4px;
    text-decoration: none;
  }

  @keyframes bounceRight {
    0% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(3px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

function Home() {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      Promise.all([
          getNowPlayingItem(),
      ]).then((results) => {
          setResult(results[0]);
          setLoading(false);
      });
    }, 5000);
  
    return () => clearInterval(interval);
  }, [loading, result]);

  const getNowPlaying = () => {
    if (!loading && result) {
      return <> I'm listening to&nbsp;<Hyperlink secondary style={{fontSize: '12px', color: 'brown'}} href={result.songUrl}>{result.title}</Hyperlink>&nbsp;rn.</>;
    }
  }

  const toggleDrawer = () => {
    setDrawerVisible(true);
  };

  return (
    <>
    <Helmet>
        <title>Ashank Behara</title>
        <meta name="description" content="I'm the biggest bird"/>
        <meta property="og:title" content="Ashank Behara" />
        <meta property="og:description" content="I'm the biggest bird" />
        <meta property="og:url" content="https://ashank.xyz" />
        <meta property="og:type" content="website" />
      </Helmet>
    <div className="App">
      <Solo>
        <Orbitron onClick={toggleDrawer}>
          Ashank Behara
          <Drawer visible={drawerVisible}>
            <a href={SPOTIFY}><FaSpotify /></a>
            <a href={TWITTER}><FaTwitter /></a>
            <a href={LINKEDIN}><FaLinkedin /></a>
            <a href={INSTAGRAM}><FaInstagram /></a>
          </Drawer>
        </Orbitron>
        <br/><br/>
        <Rokkitt><div>ShapeCI</div><hr/><Merriweather>founder</Merriweather></Rokkitt>
        <Kharla>Accelerating hardware development - <Hyperlink style={{fontSize: '11px'}}href="https://www.shape.ci">www.shape.ci</Hyperlink></Kharla>
        <Rokkitt><div>NVIDIA</div><hr/><Merriweather>deep&nbsp;learning&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>DeepMap: 3D mapping, lidar seg + training infra</Kharla>
        <Rokkitt><div>Tesla</div><hr/><Merriweather>manufacturing&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>Cell manufacturing, mostly sw/cv tooling for 4680 cells</Kharla>
        <Rokkitt><div>3M</div><hr/><Merriweather>r&d&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>CV research in Corporate Research Lab</Kharla>
        <Rokkitt><div>Raad</div><hr/><Merriweather>robotics&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>6-axis robot design + controls</Kharla><br/>
        <SpaceGrotesk>I dropped out of mechanical engineering and computer science degrees at the University of Illinois. I'm now and forever on a mission to accelerate hardware development.</SpaceGrotesk><br/>
  
        <Table>
          <Spectral>Thinking</Spectral>
          <Nunito>ThoughtsOn&nbsp;<Hyperlink href="/words">things</Hyperlink>&nbsp;from ThoughtSon</Nunito>
          <Spectral>Music</Spectral>
          <Nunito>Used to sing + play a few instruments and learned to&nbsp;<Hyperlink href="/munch">deejay</Hyperlink>&nbsp;for 2 months. {getNowPlaying()}</Nunito>
          <Spectral>Sports</Spectral>
          <Nunito>Okay tennis player (~usta 4.0 now) that can hold my own on the basketball court</Nunito>
          <Spectral>Pokémon</Spectral>
          <Nunito>Peaked at ~1680 gen 6 showdown and broke 1700 on gen 9. No vgc.</Nunito>
          
        </Table><br/>
        {/* <SocialWrapper><Social href={TWITTER}>Twitter</Social><Social href={LINKEDIN}>Linkedin</Social><Social href={INSTAGRAM}>Instagram</Social><Social href={READCV}>Resumé</Social></SocialWrapper> */}
      </Solo>
    </div>
    </>
  );
}

export default Home;
