import bama from '../static/bama.png'
import "../App.css";
import styled from "styled-components";
import { Helmet } from 'react-helmet-async';

const Solo = styled.div`
    @media only screen and (max-width: 768px) {  
    /* Your CSS Code for this device size */    
      margin-left: 10%;
      margin-right: 10%;
      width: 80%;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 768px) {  
    /* Your CSS Code for this device size */    
      margin-left: 25%;
      margin-right: 25%;
      width: 50%;
    }
  // gap: 5%;
  margin-top: 10%;
  text-align: start;
`;

const Orbitron = styled.div`
  font-family: Orbitron;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  cursor: help;
`;

const Kharla = styled.div`
  font-family: Open Sans;
  font-size: 15px;
  margin-bottom: 7px;
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 6fr;
  grid-gap: 5px;
`

const Spectral = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  align-items: center;
  /* text-decoration: underline; */
`

const Nunito = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  display: flex; /* Add this line */
  align-items: center; /* Ensures vertical alignment */
  flex-wrap: wrap; /* Allows items to wrap if needed */
  padding-top: 1px;
  display: inline-block;
`

const Hyperlink = styled.a`
  font-family: Open Sans;
  overflow-wrap: normal;
  word-break: normal;
  color: blue;
  text-decoration: none;
  white-space: nowrap;
  font-size: 12px;
  :hover {
    cursor: help;
  }
`

export const AppleMapsChapel = () => <Hyperlink href="https://maps.apple.com/?address=40842%20Fremont%20Blvd,%20Fremont,%20CA%20%2094538,%20United%20States&auid=6076509467742363483&ll=37.534597,-121.961689&lsp=9902&q=Berge-Pappas-Smith%20Chapel%20of%20the%20Angels&t=m">{"[apple maps]"}</Hyperlink>
export const GoogleMapsChapel = () => <Hyperlink href="https://www.google.com/maps/place/Berge-Pappas-Smith+Chapel+Of+The+Angels/@37.5343808,-121.9617081,15z/data=!4m16!1m9!3m8!1s0x808fc0ce89e6c40b:0x3b5b56e738510f6c!2s40842+Fremont+Blvd,+Fremont,+CA+94538!3b1!8m2!3d37.5347123!4d-121.9615752!10e5!16s%2Fg%2F11bw42vfmf!3m5!1s0x808fc0ce89138dc7:0x814646dbfee5da3d!8m2!3d37.5345683!4d-121.9616594!16s%2Fg%2F1tkkv2zp?entry=ttu&g_ep=EgoyMDI1MDEwNi4xIKXMDSoASAFQAw%3D%3D">{"[google maps]"}</Hyperlink>
export const AppleMapsCrematory = () => <Hyperlink href="https://maps.apple.com/?address=41001%20Chapel%20Way,%20Fremont,%20CA%20%2094538,%20United%20States&auid=14970058632032746952&ll=37.531605,-121.964530&lsp=9902&q=Irvington%20Memorial%20Cemetery&t=m">{"[apple maps]"}</Hyperlink>
export const GoogleMapsCrematory = () => <Hyperlink href="https://www.google.com/maps/place/41001+Chapel+Way,+Fremont,+CA+94538/data=!4m2!3m1!1s0x808fc0cc058ec751:0x2b3983a4390df3a6?sa=X&ved=1t:242&ictx=111">{"[google maps]"}</Hyperlink>

function Bama() {

  return (
    <>
    <Helmet>
        <title>Hymavathi Devi Behara's Service</title>
        <meta name="description" content="Date, location, contact information for Hymavathi Devi Behara's service" />   
        <meta property="og:title" content="Service Details" />
        <meta property="og:description" content="Hymavathi Devi Behara (1940-2025)" />
        <meta property="og:url" content="https://ashank.xyz/bama" />
        <meta property="og:image" content="https://shapeci-public.s3.us-east-2.amazonaws.com/bama.png" />
        <meta property="og:type" content="website" />
    </Helmet>
    <div className="App">
      <Solo>
        <img src={bama} alt="bama" style={{width: '200px', borderRadius: '10px'}} />
        <Kharla style={{fontSize: '15px'}}>Hymavathi Devi Behara</Kharla>
        <br/>
        <Kharla style={{fontSize: '9px', fontWeight: 'bold', marginBottom: '0px'}}>Date</Kharla>
        <Kharla style={{fontSize: '12px', marginBottom: '10px'}}>January 9th, 2025 (Thursday)</Kharla>
        <Kharla style={{fontSize: '9px', fontWeight: 'bold', marginBottom: '0px'}}>Hosts</Kharla>
        <Kharla style={{fontSize: '12px', marginBottom: '10px'}}>Krishna Behara, Padma Govindaraju, Sumana Vijayagopal, Venu Govindaraju, Swati Govindaraju, Krishna Pragyan Govindaraju, Ashank Behara, Arohi Behara </Kharla>
        <Kharla style={{fontSize: '9px', fontWeight: 'bold', marginBottom: '0px'}}>Contact Information</Kharla>
        <Kharla style={{fontSize: '12px', marginBottom: '10px'}}>(650) 245-9640</Kharla>
        <Kharla style={{fontSize: '9px', fontWeight: 'bold', marginBottom: '0px'}}>Schedule Details</Kharla>
        <Table>
          <Spectral>2:00 PM</Spectral>
          <Nunito>Viewing starts at <span style={{fontStyle: 'italic'}}>Berge-Pappas-Smith Chapel Of The Angels&nbsp;&nbsp;</span><AppleMapsChapel/>&nbsp;<GoogleMapsChapel /></Nunito>
          <Spectral>2:45 PM</Spectral>
          <Nunito>Puja (Hindu prayer ceremony)</Nunito>
          <Spectral>3:45 PM</Spectral>
          <Nunito>Procession & walk to crematory</Nunito>
          <Spectral>4:00 PM</Spectral>
          <Nunito>Witness cremation at <span style={{fontStyle: 'italic'}}>Irvington Memorial Crematory&nbsp;&nbsp;</span><AppleMapsCrematory />&nbsp;<GoogleMapsCrematory /></Nunito>
        </Table><br/>
      </Solo>
    </div>
    </>
  );
}

export default Bama;