import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import mermaid from 'mermaid';
import { marked } from 'marked';
import ReactGA from 'react-ga4';

const BlogPostContainer = styled.div`
  margin-top: 7%;
    text-align: start;

    @media only screen and (max-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 10%;
        margin-right: 10%;
        max-width: 80%;
    }
    @media only screen and (min-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;

        .nowrap {
            white-space: nowrap;
        }
    }
`;

const TitleContainer = styled.h1`
  font-family: 'Orbitron', sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Orbitron', sans-serif;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
  }

  a {
    color: #0066cc;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  code {
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin: 1em 0;
    padding-left: 1em;
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  /* Mermaid styles */
  .mermaid {
    margin: 1em 0;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function BlogPost() {
  const { title } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    const pagePath = `/words/${title}`;
    ReactGA.send({ hitType: "pageview", page: pagePath });
}, [title]);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const markdownModule = await import(`../markdown/${title}.md`);
        const response = await fetch(markdownModule.default);
        const text = await response.text();
        
        // Convert markdown to HTML
        const htmlContent = marked(text);
        
        // Wrap Mermaid diagrams in a div with class 'mermaid'
        const mermaidContent = htmlContent.replace(/<pre><code class="language-mermaid">([\s\S]*?)<\/code><\/pre>/g, '<div class="mermaid">$1</div>');
        
        setContent(mermaidContent);
        
      } catch (error) {
        console.error('Error fetching markdown:', error);
        setContent('<h1>Error</h1><p>Unable to load the blog post.</p>');
      }
    };

    fetchMarkdown();
  }, [title]);

  useEffect(() => {
    if (content) {
      // Initialize Mermaid after content is set
      mermaid.initialize({ startOnLoad: true });
      mermaid.contentLoaded();
    }
  }, [content]);

  const formattedTitle = title.replace(/-/g, ' ');

  return (
    <BlogPostContainer>
      <Helmet>
        <title>{formattedTitle}</title>
        <meta property="og:title" content={formattedTitle} />
        {/* Remove the og:description meta tag */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${window.location.href}?v=${Date.now()}`} />
        {/* Add an og:image tag if you have a featured image for your blog posts */}
        {/* <meta property="og:image" content="URL_TO_YOUR_FEATURED_IMAGE" /> */}
      </Helmet>
      <TitleContainer>
        <StyledLink to="/words">My thoughts</StyledLink> / {formattedTitle}
      </TitleContainer>
      <Content dangerouslySetInnerHTML={{ __html: content }} />
    </BlogPostContainer>
  );
}

export default BlogPost;